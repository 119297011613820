import type { IncidentPermissionsResponse } from '~/types/api/Psrm.Mobile.Api.Contract.Incident';

interface IncidentPermissions {
    data: IncidentPermissionsResponse | null;
}

async function getPermissions() {
    const { $incidentService } = useNuxtApp();
    const incidentPermissionFetcher = $incidentService.getIncidentPermissionsFetcher();
    const permissions = await incidentPermissionFetcher.fetch({});
    return permissions;
}

export const incidentPermissions = defineStore('incidentPermissions', {
    state: (): IncidentPermissions => {
        return { data: null };
    },
    actions: {
        async loadPermissions(): Promise<void> {
            const permissions = await getPermissions();
            this.$state.data = permissions;
        },
    },
});
